<template>
	<modal name="ClvModalSymbolSettings" class="ClvModalSymbolSettings" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="clv_modal">
			<div class="clv_modal_head">
				<div class="clv_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="clv_modal_title">
					<span v-if="clvType == 'addSymbol'">Добавление символа</span>
					<span v-else-if="clvType == 'editSymbol'">Редактирование символа</span>
					<span v-else-if="clvType == 'deleteSymbol'">Удаление символа</span>
				</div>
			</div>
			<div class="clv_modal_body">
				<div class="clv_modal_info">
					<div v-if="['addSymbol', 'editSymbol'].includes(clvType)">
						<div class="clv_modal_info_row" v-if="clvType == 'addSymbol'">
							<div class="clv_modal_info_row_label">Символ:</div>
							<div class="clv_modal_info_row_value">
								<input type="text" class="clv_input" v-model.trim="clvSymbol" placeholder="укажите символ" autocomplete="off">
								<div v-html="clvAlertSymbol"></div>
							</div>
						</div>
						<div class="clv_modal_info_row last">
							<div class="clv_modal_info_row_label">Тип символа:</div>
							<div class="clv_modal_info_row_value">
								<input type="text" class="clv_input" v-model.trim="clvSymbolType" placeholder="укажите тип символа" autocomplete="off">
								<div v-html="clvAlertSymbolType"></div>
							</div>
						</div>
					</div>
					<div v-else-if="clvType == 'deleteSymbol'">Вы действительно хотите удалить символ?</div>
				</div>
				<div class="clv_modal_alert" v-html="clvAlert"></div>
				<div class="clv_modal_loading" :class="{ hide: !clvIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="clv_modal_buttons" :class="{ hide: clvIsLoading }">
					<button type="button" class="clv_button common" @click="close">Закрыть</button>
					<button type="button" class="clv_button common green" @click="axios" v-if="clvType == 'addSymbol'">Добавить</button>
					<button type="button" class="clv_button common green" @click="axios" v-else-if="clvType == 'editSymbol'">Сохранить</button>
					<button type="button" class="clv_button common red" @click="axios" v-else-if="clvType == 'deleteSymbol'">Удалить</button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			clvTemp: '',
			clvAlert: '',
			clvAlertSymbol: '',
			clvAlertSymbolType: '',
			clvId: 0,
			clvType: '',
			clvIndex: 0,
			clvSymbol: '',
			clvSymbolType: '',
			clvIsLoading: false,
			clvIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.clvTemp = '';
				this.resetAlerts();
				this.clvId = event.params.id;
				this.clvType = event.params.type;
				this.clvIndex = event.params.index;
				this.clvSymbol = '';
				this.clvSymbolType = '';
				this.clvIsLoading = false;
				this.clvIsError = false;

				if (this.clvType == 'editSymbol') {

					this.clvSymbol = this.$parent.clvSymbolSettingsData[this.clvIndex].index;
					this.clvSymbolType = this.$parent.clvSymbolSettingsData[this.clvIndex].type;
				}
			},
			resetAlerts: function() {

				this.clvAlert = '';
				this.clvAlertSymbol = '';
				this.clvAlertSymbolType = '';
			},
			getError: function(i) {

				this.clvIsError = true;

				if      (i == 1) { this.clvTemp = 'Необходимо указать id символа.'; }
				else if (i == 2) { this.clvTemp = 'Необходимо указать верный id символа.'; }
				else if (i == 3) { this.clvTemp = 'Необходимо указать символ.'; }
				else if (i == 4) { this.clvTemp = 'Необходимо указать верный символ.'; }
				else if (i == 5) { this.clvTemp = 'Необходимо указать тип символа.'; }
				else if (i == 6) { this.clvTemp = 'Необходимо указать верный тип символа.'; }
				else if (i == 7) { this.clvTemp = 'Id символа не найден в системе.'; }
				else if (i == 8) { this.clvTemp = 'Такой символ уже есть в системе.'; }
				else             { this.clvTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><strong>Ошибка!</strong> ' + this.clvTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('clvToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.clvApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('clvToken') }
					};

					_this.resetAlerts();
					_this.clvIsError = false;

					if (['addSymbol', 'editSymbol'].includes(_this.clvType)) {
						if (!_this.clvSymbol) {
							_this.clvAlertSymbol = _this.getError(3);
						}
						else if (!_this.$store.state.clvRegexSymbols.test(_this.clvSymbol)) {
							_this.clvAlertSymbol = _this.getError(4);
						}
						if (!_this.clvSymbolType) {
							_this.clvAlertSymbolType = _this.getError(5);
						}
						else if (!_this.$store.state.clvRegex_w.test(_this.clvSymbolType)) {
							_this.clvAlertSymbolType = _this.getError(6);
						}
						if (!_this.clvIsError) {
							config.url = '/v2/admin/account/symbol_settings';
							config.data = { id: _this.clvId, index: _this.clvSymbol, type: _this.clvSymbolType };
							config.method = _this.clvType == 'addSymbol' ? 'post' : 'patch';
						}
					}
					else if (_this.clvType == 'deleteSymbol') {
						config.url = '/v2/admin/account/symbol_settings';
						config.data = { id: _this.clvId };
						config.method = 'delete';
					}

					if (!_this.clvIsError) {
						_this.clvIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.clvType == 'addSymbol') {
									_this.$parent.clvSymbolSettingsData.push(data.data);
								}
								else if (_this.clvType == 'editSymbol') {
									_this.$parent.clvSymbolSettingsData.splice(_this.clvIndex, 1, data.data);
								}
								else if (_this.clvType == 'deleteSymbol') {
									_this.$parent.clvSymbolSettingsData.splice(_this.clvIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addSymbol', 'editSymbol', 'deleteSymbol'].includes(_this.clvType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.clvAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.clvAlert = _this.getError(2); }
										else if (data.response.data.error == 'INDEX_IS_EMPTY') { _this.clvAlertSymbol = _this.getError(3); }
										else if (data.response.data.error == 'INDEX_NOT_VALID') { _this.clvAlertSymbol = _this.getError(4); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.clvAlertSymbolType = _this.getError(5); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.clvAlertSymbolType = _this.getError(6); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editSymbol', 'deleteSymbol'].includes(_this.clvType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.clvAlert = _this.getError(7); }
									}
								}
								else if (data.response.status == 409) {
									if (_this.clvType == 'addSymbol') {
										if (data.response.data.error == 'INDEX_DUPLICATE') { _this.clvAlertSymbol = _this.getError(8); }
									}
								}
							}
							if (!_this.clvIsError) {
								_this.clvAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.clvIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('ClvModalSymbolSettings');
			}
		}
	}
</script>
